@import '../../index.scss';


.form-page, .form-page-block {
    display: flex;
    flex-direction: row;
    justify-items: center;
    justify-content: space-between;
    width: 100%;
    height: $page-height;
    background-color: white;


    h1, h2 {
        text-align: left;
    }

    hr {
        width: 100%;
        max-width: 700px;
        border: none;
        border-bottom: 1px solid $red;
    }

    .form-header-vertical {
        display: flex;
        flex-direction: column;
    }

    .form-header-horizontal {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        margin: 1.0rem 0;
    }

    .form-header-button {
        flex: 1;
        min-width: 150px;
        white-space: nowrap;
        letter-spacing: 0.04em;
        border: none;
        border-bottom: 1px solid $blue;
        margin: 0 auto;
    }


    form {
        text-align: center;
        // overflow-x: hidden;
        // width: 100%;
        max-width: 800px;
        overflow-y: auto;
        margin: 1.0rem;
        padding: 1.0rem;
        justify-items:baseline;

        display: flex;
        flex-direction: column;

        @media only screen and (max-width: $screen-tablet-width) {
            grid-template-columns: 100%;
        }

        

        .inputWrapper {
            grid-column: 2;
            max-width: 700px;

            display: grid;
            grid-template-columns: 35% calc(100% - 35% - 1.0rem);
            grid-column-gap: 1.0rem;

            label {
                text-align: right;
                grid-column: 1;
                color: black;
    
                @media only screen and (max-width: $screen-tablet-width) {
                    text-align: left;
                }
            }

            .custom-string-list-box {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: auto;

                label {
                    color: white;
                    background-color: $red;
                    padding: 0.15rem 0.5rem;
                    margin: 0.15rem 0.25rem;
                }
            }

            .custom-field {
                grid-column: 2;
            }

            .inputCheckbox {
                display: block;
                width: 1.25rem;
                height: 1.25rem;
                border: 1px solid $blue;
                border-radius: $radius;
                background-color: transparent;
                cursor: pointer;
                appearance: none;
            
                &:checked {
                    background-color: $blue;
                }
            }  
       
            .validation {
                grid-column: 2;
    
                @media only screen and (max-width: $screen-tablet-width) {
                    grid-column: 1;
                }
            }   

            #edit-role-section {
                grid-column: 2;
                display: flex;
                flex-direction: column;

                @media only screen and (max-width: $screen-tablet-width) {
                    grid-column: 1;
                }

                #role-listing {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    h5 {
                        margin-left: 1.0rem;
                        font-weight: 500;
                        color: black;
                    }
                    
                    button {
                        margin: 0;
                        color: $red;
                        background-color: transparent;
                        font-size: 1.0rem;
                        font-weight: 800;
                    }
                }

                #role-select {

                    option {
                        width: 100%;
                    }
                }

                
            }

            .slider-track {
                position: relative;
                margin: auto 0.5rem;
                padding: 0;
                height: 1px;
                border-bottom: 1px solid $blue;

                .slider-thumb {
                    
                    border: 1px solid $blue;
                    border-radius: 50%;
                    background-color: white;
                    padding: 0.2rem;
                    margin: auto;

                    .slider-thumb-label {
                        @extend .text;
                        display: block;
                        text-align: center;
                        margin: auto;
                        font-size: 0.7rem;
                        min-width: 0.7rem; //round for one or two digits
                    }
                }
            }

            .multi-range-slider {
                padding: 0;
                margin: auto 0;
                border: 0;
                box-shadow: none;
                background-color: red;
            }

            .max-min-slider {
                padding: 0;
                margin: 0;
                border: 0;
                box-shadow: none;
                background-color: red;
            }

            #custom-string-list {

                .list-left-align-box {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    justify-content: left;

                    .list-value {
                        @extend .text;
                        border: 1px solid $blue;
                        padding: 0.05rem 0.25rem;
                        margin: 0.15rem;

                        &:hover {
                            border-color: $red;
                            color: $red;
                            cursor: pointer;
                        }
                    }
                }

            }

            .custom-input-box {
                display: flex;
                flex-direction: row;

                @media only screen and (max-width: $screen-tablet-width) {
                    flex-direction: column;
                }

                input {
                    grid-column: 1;
                    width: 100%;
                }

                button {
                    grid-column: 2;
                    margin: auto;
                    margin-left: 1.0rem;
                    font-size: 1.0rem;
                    padding: 0.2rem 1.0rem;

                    @media only screen and (max-width: $screen-tablet-width) {
                        margin: auto;
                    }
                }
            }
        }
    }    
}

.circle-image, .profile-image, .thumbnail-image {
    max-height: 25vh;
    box-shadow: calc(2 * $radius) calc(2 * $radius) calc(2 * $radius) calc(2 * $radius) rgba(0, 0, 0, 0.3);
  }

.submit-button {
    grid-column: 1 /span 2;
    width: 15rem;
    margin: 0.5rem auto;
}

.secondary-button {
    grid-column: 1 /span 2;
    color: $blue;
    background-color: white;
    border: 1px solid $blue;
    white-space: nowrap;

    &:hover {
        color: white;
        background-color: $blue;
        border: 1px solid transparent;
    }
}

.alternative-button {
    grid-column: 1 /span 2;
    border-top: 1px solid $blue;
    border-radius: 0;
}

.form-header-detail-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    flex-direction: row;
    align-items: center;
    
    span {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        overflow-x: hidden;
    }

    .right-align {
        margin-left: auto;
    }

    .name {
        margin: 0 0 0.5rem 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .leader-profile-image {
        @extend .profile-image;
        box-shadow: none;
        height: 1.5rem;
        border-radius: 50%;
        margin: 0 0.5rem;
    }

    .id-left, .id-right {
        color: darkgray;
        font-size: 1.0em;
        font-weight: 400;
    };
}

// Dividers
.id, .detail {
    color: darkgray;
    font-size: 0.9em;
    font-weight: 400;
    border-radius: 0;
    white-space: nowrap;
}

.id-left {
    @extend .id;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    border-left: 1px solid darkgray;
}

.id-right {
    @extend .id;
    margin-right: 0.5rem;
    padding-right: 0.5rem;
    border-right: 1px solid darkgray;
}
  

.form-page-block {
    display: block;
    width: fit-content;
    height: fit-content;
    max-height: 70vh;    
    overflow: scroll;
}

.form-page-stretch {
    form {
        width: 100%;
        margin-right: 5%;
    }
}

.form-page-popup {
    form {
        @extend form;
        width: max-content;
        margin-right: 5%;
    }
}


#confirm-delete,
#image-upload {

    .center-absolute-inside {
        display: flex;
        flex-direction: column;
        justify-content: start;    

        .form-header-image {
            max-width: min(50vw, 700px);
            max-height: 30vh;
            margin: 1.0rem auto;
        }  
    }
}

#image-upload {
    .image-upload-drag-area {
        height: 40vh;
        margin: 1.0rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 2px dashed $blue;
        border-radius: 1rem;
    }      

    .image-upload-drag-area.drag-active {
        background-color: $blue;

        button {
            background-color: white;
            color: $blue;
        }
    }

    /* Cover Full View Port */
    .image-upload-drag-active {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: $radius;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

#partnership-status,
#partnership-delete-all {

    .center-absolute-inside {
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .matching-profile-box {
        width: 100%;
        margin: 1.0rem auto 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;

        &:last-child {
            position: absolute;
            right: 0;
        }
    }

    .update-partnership-inputs {
        margin: 0.5rem auto 2.0rem auto;
        display: grid;
        grid-template-columns: 1fr 300px 1fr;
        max-width: max-content;
        grid-auto-flow: row dense;
        gap: 0 1.0rem;

        input {
            text-align: center;
            max-width: 4.0rem;            
        }     
        
        option {
            text-align: center;
        }
    }

    .search-item {
        border: none;
    }
}
