$background: white;
$red: #B12020;
$blue: #62D0F5;
$blue-dark: #003f89;
$gray-dark: #303030;
$gray-light: #A9A9A9;
$t-black: rgba(0, 0, 0, 0.7);
$t-black-dark: rgba(0, 0, 0, 0.9);
$t-white: rgba(255, 255, 255, 0.2);
$radius: 4px;

$page-height: 100vh;

$screen-mobile-width: 576px;
$screen-tablet-width: 768px;
$screen-laptop-width: 991px;
$screen-desktop-width: 1200px;

html {
  font-size: 22px;
  color: black;
  font-family: 'Roboto';
  font-weight: 300;
  letter-spacing: 0.04rem;
  background-color: $background;

  scroll-behavior: smooth;
  overflow-x: hidden;

  @media screen and (max-width: $screen-desktop-width) {
    font-size: 15px;
  }

  @media screen and (max-width: $screen-laptop-width) {
    font-size: 14px;
  }

  @media screen and (max-width: $screen-mobile-width) {
    font-size: 12px;
  }
}

body {
  margin: 0;
  overflow: hidden;
}

/* DISPLAY CONTROLS */
@mixin HIDE {
  display: none;
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
}

.none, .hide {
  @include HIDE;
}

@mixin HIDE-ON-MOBILE {
  @media screen and (max-width: $screen-mobile-width) {
    @include HIDE;
  }
}

.hide-mobile {
  @include HIDE-ON-MOBILE;
}

@mixin HIDE-ON-TABLET {
  @media screen and (max-width: $screen-tablet-width) {
    @include HIDE;
  }
}

.hide-tablet {
  @include HIDE-ON-TABLET;
}


/* ELEMENT DEFAULTS */
div,
label,
button,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  background-color: transparent;
  border: none;
  border-radius: $radius;
  font-weight: 300;
  margin: 0.3rem 0;
}

div {
  margin: 0;
  padding: 0;
}

button {
  margin: 1.0rem auto;
  padding: 0.3rem 1.0rem;
  font-family: 'EB Garamond';
  font-weight: 600;
  font-size: 1.3rem;
  cursor: pointer;

  background-color: $red;
  color: white;

  &:hover {
    background-color: $blue;
  }
}

NavLink, Link {
  text-decoration: none;
}

h1,
.header {
  font-family: 'Playfair Display';
  font-weight: 700;
  font-size: 2.0rem;
  color: $red;
}

h2,
.sub-header {
  font-family: 'Playfair Display';
  font-weight: 600;
  font-size: 1.50rem;
  color: $red;
}

h3,
h4,
label,
.title {
  font-family: 'EB Garamond';
  font-weight: 500;
  font-size: 1.15rem;
  color: $blue;
}

h4, h5 {
  color: $red;
}

h5,
h6,
p,
.text {
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 1.0rem;
  color: black;
}

h6 {
  color: $blue;
}


/* INPUT DEFAULTS */
input, select, textarea {
  text-align: left;
  margin: 0.25rem 0;
  padding: 0.15rem 0.7rem;
  border: 1px solid $blue;
  border-radius: 4px;
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 0.9rem;
  background-color: white;



}

input, textarea {
    border: none;
    border-bottom: 1px solid $blue;
    border-radius: 0;

    &:hover, &:active {
        border: 1px solid $blue;
        border-radius: 4px;
    }
}

textarea {
  height: 5.0rem;
}

/* Popup */
.center-absolute-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* Stay in place */
  z-index: 15; /* Sit on top */
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: $t-black;

  .center-absolute-inside {
    background-color: white;
    padding: 2.0rem;
    border-radius: calc(2 * $radius);
    box-shadow: calc(3 * $radius) calc(4 * $radius) $t-black;
    min-width: 40vw;
    max-width: min(700px, 80vw);
    max-height: 80vh;
    overflow-x: hidden;
    overflow-y: auto;

    @media screen and (max-width: $screen-mobile-width) {
      min-width: none;
      max-width: 90vw;
    }
  }
}

.validation {
  color: $red;
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 0.7rem;
  font-style: oblique;
  margin: 0 0 1.0rem auto;
}

.alternative-button {
  background-color: transparent;
  color: $blue;
  font-size: 0.9em;
  min-width: 15rem;

  &:hover {
      color: white;
      background-color: $blue;
      border: 1px solid $blue;
  }
}

.icon-button {
  display: grid;
  grid-auto-flow: column;
  align-items: baseline;
  color: $blue;
  background: none;
  border: none;
  margin: 0;
  padding: 0.3rem 1.0rem;

  .icon-button-icon {
    @extend .title;
    font-size: 1.5rem;
    max-width: 1.5rem;    
  }

  .icon-button-label {
    @extend .title;
    @extend .hide-mobile;
    margin-left: 0.5rem;    
  }

  &:hover {
    background: $blue;
    cursor: pointer;

    .icon-button-icon, .icon-button-label {
      color: white;
    }
  }
}

.circle-image, .thumbnail-image {
  object-fit: contain;
  border-radius: calc(2 * $radius);
  margin: 0 auto 1.0rem auto;
}

.profile-image {
  object-fit: contain;
  border-radius: 50%;
  margin: auto;
}


/* ANIMATIONS */
@keyframes zoom-pulse {
  0%,100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

@keyframes border-pulse-red {
  0%,100% {
    border-color: transparent;
  }
  50% {
    border-color: $t-white;
  }
}

@keyframes border-pulse-blue {
  0%,100% {
    border-color: transparent;
  }
  50% {
    border-color: $blue;
  }
}

@keyframes outline {
  0% {
    background-size: 0 1px,
      1px 0,
      0 1px,
      1px 0;
  }

  100% {
    background-size: 100% 1px,
      1px 100%,
      100% 1px,
      1px 100%;
  }
}

@keyframes trace {
  0% {
      background-size:    0 3px,
                          3px 0,
                          0 3px,
                          3px 0;
  }
  25% {
      background-size:    100% 3px,
                          3px 0,
                          0 3px,
                          3px 0;
  }
  50% {
      background-size:    100% 3px,
                          3px 100%,
                          0 3px,
                          3px 0;
  }
  75% {
      background-size:    100% 3px,
                          3px 100%,
                          100% 3px,
                          3px 0;
  }
  100% {
      background-size:    100% 3px,
                          3px 100%,
                          100% 3px,
                          3px 100%;
  }
}

@keyframes fade-out {
  to {
      opacity: 0;
  }
}
