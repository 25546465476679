@import '../index.scss';

#login-page, #sign-up-page {
    background-color: $gray-dark;
    border-radius: 0;

    #popup-wrapper {
        z-index: 10;
    }

    #shape-rectangle {
        background-color: $red;
        border-radius: 0;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 60vh;
    }

    #shape-curve {
        @extend #shape-rectangle;
        position: absolute;
        background-color: transparent;
        overflow: hidden;
        z-index: 1;
        margin-top: 59vh;
        height: 35vh;

        svg {
            height: 100%;
            width: 100%;
            

            path {
                stroke: white;
                stroke-width: 1px;
                fill: $red;
            }
        }
    }
}


#logo-box {
    display: grid;
    grid-auto-flow: column;
    margin-bottom: 1.0rem;

    img, .profile-image {
        height: 2.5rem;
        margin: auto 0.5rem auto 0;
    }

    h1 {
        font-size: 2rem;
        color: $blue;
        white-space: auto;
    }
}

#circle-announcement-popup {
    form {
        width: max-content;
        margin: auto;
    }
}

#partnership-contract {

    .center-absolute-inside {
        display: flex;
        flex-direction: column;
        justify-content: start;    

        .partner-detail-box {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin: 1.0rem auto;
    
            .form-header-image {
                max-height: 10vh;                
            }  

            h3 {
                margin-left: 2.0rem;
             }    
        }   
        
        .contract {
            margin: 1.5rem;
            text-align: center;
            line-height: 1.5;
            overflow-y: scroll;
        }
    }    
}
