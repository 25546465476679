@import '../index.scss';
@import '../2-Widgets/SearchList/searchList.scss';
@import '../2-Widgets/SearchList/searchListItemCards.scss';


#partnership-page {
    display: flex;
    flex-direction: row;

    .main-content {
        margin-right: auto;
        width: 100%;

        .grid-container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-auto-rows: auto;
            grid-column-gap: 0;
            grid-row-gap: 0;
            overflow-y: auto;
            height: $page-height;

            .grid-header {
                grid-row: 1;
                position: sticky;
                top: 0;
                z-index: 1;
                background-color: white;
                border-bottom: 2px solid $blue;
                border-radius: 0;
                padding: 0.5rem 1.0rem 0 1.0rem;
                text-align: center;
                white-space: wrap;
                word-wrap: break-word;
            }

            h1 {
                margin: 0;
            }
    
            .search-item {
                padding: 1rem;
                border-bottom: 1px solid $gray-light;
                border-width: 2px;
                border-radius: 0;
    
                &:hover {
                    border-color: $blue;
                }
    
                .status-count {
                    color: $gray-light;
                }
            }
        }


        #dual-view {
            max-width: 1000px;

            .search-item {
                padding: 1.5rem 2.5rem;

                //Re-using as date, removing hover feedback
                .search-item-alternative-button {
                    color: $gray-light;
    
                    &:hover {
                        color: $gray-light;
                        background-color: inherit;
                        border: 1px solid transparent;
                    }
                }
            }

            /* Target items in the left column */
            .grid-container > :nth-child(2n+1) {
                margin-left: 7vw;
            }
    
            /* Target items in the right column */
            .grid-container > :nth-child(2n) {
                margin-right: 7vw;
            }    
        }


        #status-view {
            margin: 0;
            overflow-y: auto;

            .grid-header {
                display: flex;
                align-items: flex-end;
                justify-content: flex-start;
                padding: 0;            
            }

            h1 {
                margin-left: 1.0rem;
            }

            .grid-header-rotate {
                height: 150px;
                width: 100%;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                overflow: hidden;
                
                h3 {
                    width: 100%;
                    height: auto;
                    white-space: nowrap;
                    text-align: left;
                    margin: 0 auto 25% auto;
                    padding: 0;
                    font-size: 1.15rem;
                    
                    transform-origin: center bottom;
                    transform: 
                        translate(calc(1.15rem / 2), -100%)
                        rotate(-90deg);
                }
            }

            h3 {
                padding: 0.5rem 0.15rem;
                text-align: center;
                align-items: baseline;
            }

            .status-count {
                @extend h3;
                margin: auto;
            }

            .search-item {
                border-color: transparent;

                &:hover, &.selected {
                    border-color: $blue;
                }
            }
        }
    }
}
