@import '../../index.scss';

#animation-page {
    position: absolute;
    z-index: 11;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $gray-dark;
    border-radius: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    
    #animation-logo {
        max-height: 1000px;
        max-width: 90vw;
    }

    #animation-logo-mobile {
        max-height: 600px;
        max-width: 90vw;
    }

    & {
        animation: fade-out 1.4s ease-out 5.6s forwards;
    }
}
