@import '../index.scss';

#app {
    width: 100vw;
    height: 100vh;
    display: flex;
    grid-auto-flow: column;
    justify-items: start;
    align-items: start;
    background-color: $background;
    color: black;



    #app-navigation {
        z-index: 10;
        display: flex;
        flex-direction: column;
        background-color: $gray-dark;
        color: whitesmoke;
        width: min-content;
        border-radius: 0;
        margin: 0;
        padding: 0.5rem 0;
        height: calc(100vh - 1.0rem);

        #app-menu {
            display: flex;
            flex-direction: column;
            overflow-y: auto;
        }

        $leading-column-width: 45px;
        $trailing-column-width: 2.0rem;

        .page, .sub-page {
            display: grid;
            grid-template-columns: $leading-column-width auto $trailing-column-width;

            text-decoration: none;
            margin: 0;
            padding: 0.15rem;
            border-radius: 0;

            &.sub-page {
                padding: 0 0.15rem;
            }

            .page-icon {
                grid-column: 1;
                align-self: center;
                justify-self: center;
                margin: 0;
                height: calc($leading-column-width * 0.85);
                max-width: $leading-column-width;

                font-size: 2.5rem;
            }

            .page-label {
                grid-column: 2;
                font-family: 'EB Garamond';
                font-weight: 500;
                font-size: 1rem;
                text-decoration: none;
                white-space: nowrap;
                color:whitesmoke;
                margin: auto 0;
                margin-left: 0.25rem;

                .sub-page & {
                    font-size: 1.0rem;
                }
            }

            &.sub-page .page-label {
                border-left: 2px solid $blue;
                border-radius: 0;
                padding: 0.15rem 0.75rem;
                font-size: 1.0rem;
            }

            .add-button-wrapper {
                grid-column: 3;
                display: inline-block;
                text-decoration: none;
                justify-self: center;
                margin: auto 1.0rem;

                .add-button {
                    display: inline-block;
                    min-width: $trailing-column-width;

                    p {
                        font-size: 0.9rem;
                        margin: auto;
                        padding: 0.1rem;
                        text-align: center;
                        color: $blue;
                    }

                    
                }
            }

            .active-icon {
                display: none;
            }

            .inactive-icon {
                display: block;
            }

            &.active, &:hover {
                background-color: $red;
                cursor: grab;
    
                h1, h2, h3, h4, h5, h6, label, p, .page-label {
                    color: white;
                    cursor: grab;
                }

                .add-button-wrapper {
                    .add-button {
                        p {
                            color: white;
                        }

                        &:hover {
                            background-color: white;
                            border-radius: 50%;
    
                            p {
                                color: $red;
                                cursor: pointer;
                            }
                        }
                    }
                }

                .active-icon {
                    display: block;
                }

                .inactive-icon {
                    display: none;
                }
            }
        }

        .hide {
            display: none;

            &:not(.active-icon):hover {
                display: block;
            }

            &:not(.inactive-icon):hover {
                display: block;
            }

        }

        $title-line-height: 1.5rem;
        #logo-box {
            align-items: start;
            min-height: calc($title-line-height * 2); //Match h1

            padding-bottom: 0.5rem;
            border-bottom: 2px solid $t-white;

            img {
                width: $leading-column-width;
                height: auto;
            }

            h1 {
                grid-column: 2 / span 3;
                padding-right: 1.0rem;
                justify-self: start;
                align-self: center;
                text-decoration: none;
                text-align: left;
                font-size: $title-line-height;
                line-height: $title-line-height;
                white-space: wrap;
                margin: 0;
                margin-left: 0.5rem;
            }
        }

        #profile-box {
            justify-self: flex-end;
            margin-top: auto;
            padding-top: 0.5rem;
            border-top: 2px solid $t-white;

            .profile-image {
                margin: auto;
                border-radius: 50%;
                box-shadow: none;
            }

            #profile-box-vertical {
                grid-column: 2 / span 3;
                justify-self: start;
                padding-right: 1.0rem;
                margin-left: 0.5rem;

                display: flex;
                flex-direction: column;
                justify-content: start;

                h2 {
                    margin: 0;
                    color: $blue;
                    font-size: 1.15rem;
                }

                h5 {
                    margin: 0;
                    color: white;
                    font-size: 0.6rem;
                }
            }

            &:hover {
    
                #profile-box-vertical {
                    
                    h2, h5 {
                        color: white;
                    }
                }
            }
        }

        //Detect outside click
        #absolute-wrapper {
            display: block;
            position: fixed; /* Stay in place */
            z-index: 1; /* Sit on top */
            left: 0;
            top: 0;
            width: 100vw;
            height: 100vh;
            background-color: transparent;
        }

        $close-button-size: 1.75rem;

        #menu-close-button {
            display: flex;
            position: relative;
            align-items: center;
            justify-items: center;
            height: $close-button-size;
            width: $close-button-size;
            left: calc(-0.25 * $close-button-size);
            margin: calc(-0.5 * $close-button-size) calc(-0.75 * $close-button-size) 0 auto;
            padding: 0;

            border: 2px solid $t-white;
            border-radius: 50%;
            background-color: $gray-dark;

            p {
                display: inline-block;
                position: relative;               
                margin: 0;
                padding: 0;

                font-size: calc($close-button-size * 0.75);
                font-weight: 900;
                color: white;

                &.less-than {
                    top: calc(-0.1 * $close-button-size);
                    left: calc(0.2 * $close-button-size);
                }

                &.greater-than {
                    top: calc(-0.1 * $close-button-size);
                    left: calc(0.3 * $close-button-size);
                }
            }

            &.hide {
                display: flex;
                visibility: hidden;
            }

            &:hover {
                background-color: $blue;

                p {
                    color: white;
                }
            }
        }

        &.collapse {

            .page, #profile-box {
                grid-template-columns: $leading-column-width 0 0;

                #profile-box-vertical {
                    padding-right: 0;
                }
            }
        }

        #profile-menu {
            position: fixed;
            bottom: 3.5rem;
            z-index: 5;
            width: max-content;
            background-color: $t-black-dark;

            .page {
                grid-template-columns: $leading-column-width auto;

                &:nth-child(n) {
                        border-bottom: 1px solid $t-white;         
                }
            
                &:last-child {
                        border-bottom: none;   
                }
            }            
        }
        
        &:hover {
            #menu-close-button {

                &.hide {
                    display: flex;
                    visibility: visible;
                }
            }
        }
    }

    #app-content {
        margin: 0;
        height: $page-height;
        width: 100%;
        border-radius: 0;
        overflow: hidden;
    }
}
