@import '../index.scss';

#edit-content-archive {

    #content-wrapper {
        display: flex;
        justify-content: center;
        
        width: 100%;        
        max-height: 30vh;
        overflow-y: auto;
        margin-bottom: 1.0rem;

        #content-inner {
            height: 95%;
            max-width: 400px;
            margin: auto;

            .content-image, .thumbnail-image {
                object-fit: contain;
                height: 100%;
                width: 100%;
                max-width: 500px; //Image size
            }

            p {
                color: $blue;
                padding: 1.0rem;
            }

            a, script, iframe {
                pointer-events: none;
            }
        }
    }
}

