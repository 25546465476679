@import '../../index.scss';

#search-side-component {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: min(30vw, 400px);
    margin: 1.0rem;
    overflow: hidden;
    height: calc($page-height - 3.0rem);

    

    #search-header {
        display: flex;
        flex-direction: column;

        #search-header-menu {
            background-color: $red;
            color: white;
            font-weight: 700;
            border: none;

            text-align: center;
            padding: 0.5rem;

            &:hover, &:active {
                cursor: pointer;
                
            }

            option {
                font-weight: 500;
                background-color: white;
                color: black;
                padding: 0.3rem;

                &:hover, &:active {
                    background-color: $blue;
                    cursor: pointer;
                    
                }
            }
        }

        #search-header-search {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            width: 100%;

            #search-header-field {
                width: 100px;
                flex-grow: 1;
                margin: 0.5rem 1.0rem 0 0.5rem;
                text-align: center;
            }

            #search-header-refine {
                font-size: 0.8em;
                padding: 0.15rem 0.25rem;
                margin: auto 0 0 0;
                text-align: center;

                &:hover, &:active {
                    cursor: pointer;                    
                }
    
                option {
                    // font-size: 0.6em;
                    font-weight: 500;
                    background-color: white;
                    color: $blue;
                    padding: 0.3rem;
                    text-align: center;
    
                    &:hover, &:active {
                        background-color: $blue;
                        cursor: pointer;
                        
                    }
                }                
            }
        }
    }
}

/* #search-list and cards in searchListItemCards.scss */

.search-item-button-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    margin-top: 0.25rem;


    .search-item-alternative-button, .search-item-primary-button {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 0.7em;
        padding: 0.15rem 0.5rem;
        margin: 0 0.5rem;
        background-color: white;
        color: $blue;
        border: 1px solid transparent;
        white-space: nowrap;

        &:hover {
            color: white;
            background-color: $blue;
            border: 1px solid transparent;
        }
    }

    .search-item-primary-button {
        border-color: $blue;
    }
}
