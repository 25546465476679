@import '../../index.scss';

#full-image-page {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: $page-height;
    width: 100%;
    background-color: $gray-dark;
    border-radius: 0;

    &.full-page {
        position: absolute;
        z-index: 11;
        width: 100vw;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        overflow: hidden;
    }

    .scroll-body {
        position: relative;
        display: flex;
        flex-shrink: 1;
        flex-grow: 0;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding: 5vw;
        overflow-y: auto;

        &.full-page-body {
            padding: 10vh 15vw;
        }

        #image {
            flex-shrink: 1;
            flex-grow: 0;
            object-fit: contain;
            width: 100%;
            max-width: 600px;
            height: auto;
            max-height: 75%;
            margin-bottom: 5vh;
        }

        h2 {
            color: white;
            white-space: wrap;
            text-align: center;
        }
    }
}
