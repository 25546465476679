@import '../index.scss';

#dashboard-page {
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    img {
        width: 100%;
        object-fit: cover;
    }
}